<template>
  
  <MDBContainer>
        <MDBRow class="align-items-start">

    
          <MDBCol>
            <div class="p-5 text-center">
              <h1 class="mb-3">Blog</h1>
              <p class="lead">
                  Our current mission is to build , Support and empower World Wide Tamil Community and Tamil Language
                  through Crypto Token Ecosystem to fulfill the basic needs of each Tamil member.
              </p>

        

              <h1 class="mb-3">Vision</h1>
              <p class="lead">
                   
                Empowering Tamil Language and Tamil Tech startups to grow 
                further
                than they could alone in digital space.
              </p>
            
              
             <!-- <a class="btn btn-primary btn-lg" href="/dashboard" role="button">Get TAMIL Crypto Now</a> -->
            </div>
          </MDBCol>

      </MDBRow>


     </MDBContainer>
</template>

<script>
import {  MDBRow,MDBContainer } from 'mdb-vue-ui-kit';

export default {
  components: {
    MDBRow,
    MDBContainer
    
  },
};
</script>


<style lang="scss" scoped>
.flip {
  position: absolute;
  width: 300px;
  height: 300px;
  &:hover {
    .flip-inner {
      transform: rotateY(180deg);
    }
  }
  .flip-inner {
    transform-style: preserve-3d;
    transition: transform .8s ease;
    height: 100%;
    width: 100%;
    .front, .back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      border-radius: 50%;
      overflow: hidden;
    }
    .front {
      transform: translateZ(1px);
    }
    .back {
      transform: rotateY(180deg);
    }
    .thumbnail {
      width: 100%;
    }
  }
}
</style>